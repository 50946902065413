<template>
	<!-- 积分商品编辑 -->
	<div class="integral-edit el-content">
		<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
			<a-card title="基本信息">
				<a-row>
					<a-col :span="12">
						<a-form-item label="商品名称">
							<a-input v-model:value="form.name"></a-input>
						</a-form-item>
						<a-form-item label="商品分类">
							<a-select v-model:value="form.category_id" placeholder="请选择商品分类">
							    <a-select-option v-for="(item,index) in typeData" :key="index" :value="item.id">{{item.name}}</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="重量">
							<a-input v-model:value="form.weight"></a-input>
						</a-form-item>
						<a-form-item label="商品类型">
							<a-radio-group v-model:value="form.type">
							    <a-radio :value="1">实物商品</a-radio>
							    <a-radio :value="2">优惠券</a-radio>
							    <a-radio :value="4">兑换余额</a-radio>
							</a-radio-group>
						</a-form-item>
						
						<a-form-item label="优惠券ID" v-if="form.type == 2">
							<a-input v-model:value="form.exchange_goods_id"></a-input>
						</a-form-item>
						<a-form-item label="排序">
							<a-input v-model:value="form.rank"></a-input>
						</a-form-item>
					</a-col>
					<a-col :span="12">
						<a-form-item label="是否上架">
							<a-radio-group v-model:value="form.status">
							    <a-radio :value="1">上架</a-radio>
							    <a-radio :value="0">下架</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item label="商品简单描述">
							 <a-input type="textarea" v-model:value="form.desc"></a-input>
						</a-form-item>
						<a-form-item label="图片展示">
							<kd-img-select :mutil="true" :src="form.images" @change="(url)=>{ form.images = url}"></kd-img-select>
						</a-form-item>
					</a-col>
				</a-row>
			</a-card>
			<a-card title="积分/价格/库存设置" style="margin-top: 24px;">
				<a-row>
					<a-col :span="12">
						<a-form-item label="原价">
							<a-input v-model:value="form.amount_org" type="number" addon-after="元"></a-input>
						</a-form-item>
						<a-form-item label="所需支付积分">
							<a-input v-model:value="form.integral" type="number"></a-input>
						</a-form-item>
						<a-form-item label="所需支付金额">
							<a-input v-model:value="form.amount_dis" type="number" addon-after="元"></a-input>
						</a-form-item>
						<a-form-item label="可兑换金额" v-if="form.type ==4">
							<a-input v-model:value="form.vr_vert_amount" type="number"  addon-after="元"></a-input>
						</a-form-item>
					</a-col>
					<a-col :span="12">
						<a-form-item label="库存">
							<a-input v-model:value="form.number" type="number"></a-input>
						</a-form-item>
						<a-form-item label="销量">
							<a-input v-model:value="form.sale_num" type="number"></a-input>
						</a-form-item>
						<a-form-item label="虚拟销量">
							<a-input v-model:value="form.sale_vr_num" type="number"></a-input>
						</a-form-item>
					</a-col>
				</a-row>
			</a-card>
			
			<a-card title="详情设置" style="margin-top: 24px;">
				<a-form-item label="详情">
					<tinymce-editor v-model="detail.content"></tinymce-editor>
				</a-form-item>
				<a-form-item :wrapper-col="{ offset: 3}">
					<a-space>
						<a-button type="primary" @click="submitSave" >保存</a-button>
						<a-button @click="$router.go(-1)">返回</a-button>
					</a-space>
				</a-form-item>
			</a-card>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import integralModel from '@/api/addons/integral'
export default{
	setup(){
		const state = reactive({
			form:{
				goods_id:0,
				name:'',
				type:1,
				desc:'',
				rank:99,
				weight:'',
				images:[],
				number:'',
				status:1,
				content:'',
				integral:'',
				amount_dis:'',
				sale_num:'',
				category_id:'',
				amount_org:'',
				sale_vr_num:'',
				vr_vert_amount:0,
				outlet_class:0,
				exchange_goods_id:''
			},
			detail:{
				content:''
			},
			typeData:[],
		})
		const opt = useRoute().query
		if( opt.goods_id ){
			integralModel.getIntegralDetail(opt.goods_id,res=>{
				state.form.goods_id =res.goods_id
				state.form.name =res.name
				state.form.type =res.type
				state.form.desc =res.desc
				state.form.rank =res.rank
				state.form.weight =res.weight
				state.form.images =res.images
				state.form.number =res.number
				state.form.status =res.status
				state.form.content =res.content
				state.form.integral =res.integral
				state.form.amount_dis =res.amount_dis
				state.form.sale_num =res.sale_num
				state.form.category_id =res.category_id
				state.form.amount_org =res.amount_org
				state.form.sale_vr_num =res.sale_vr_num
				state.form.vr_vert_amount =res.vr_vert_amount
				state.detail.content =res.detail.content 
				state.detail.exchange_goods_id =res.detail.exchange_goods_id 
			})
		}

		integralModel.getIntType(1,999,res=>state.typeData = res.list)

		function submitSave(){
			let { form,detail } = state
			integralModel.addOrEditIntegral(form,detail)
		}

		return{
			...toRefs(state),
			submitSave
		}
	}
}
</script>

<style lang="scss">
	
</style>
